<template>
   <v-layout align-start>
      <v-flex>
         <v-container grid-list-md fluid>
            <!-- DIALOG CREAR/EDITAR INFORMACION -->
            <v-dialog v-model="dialogUserInfo" persistent max-width="500px">
               <v-form>
                  <v-card>
                     <v-card-text class="text--primary">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5 font-weight-medium">{{
                                    editedIndex == 0
                                       ? 'Editar información'
                                       : 'Nuevo usuario'
                                 }}</span>
                              </v-flex>
                           </v-layout>
                           <v-layout wrap>
                              <v-flex v-if="false" xs12>
                                 <v-text-field
                                    v-model="id"
                                    disabled
                                    label="ID"
                                    prepend-icon="far fa-id-card"
                                    color="primary"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12>
                                 <v-text-field
                                    v-model="username"
                                    label="Username"
                                    prepend-icon="fas fa-user"
                                    color="primary"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex v-if="editedIndex == 1" xs12>
                                 <v-text-field
                                    v-model="password"
                                    label="Contraseña"
                                    :append-icon="
                                       !showPassword ? 'fa-eye' : 'fa-eye-slash'
                                    "
                                    :type="showPassword ? 'text' : 'password'"
                                    prepend-icon="fas fa-unlock-alt"
                                    @click:append="showPassword = !showPassword"
                                    @keypress.enter="login()"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="asesorNombre"
                                    label="Nombre"
                                    color="primary"
                                    prepend-icon="fas fa-address-card"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-select
                                    v-model="selectNivelAsesor"
                                    :items="cmbNiveles"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Nivel de asesor"
                                    placeholder="Seleccionar..."
                                 ></v-select>
                              </v-flex>
                              <v-flex v-if="selectNivelAsesor > 0" xs12>
                                 <v-layout wrap>
                                    <v-flex xs12 class="text-h6">
                                       <span>Tipo de asesor</span>
                                    </v-flex>
                                    <v-flex xs12 sm6 pl-10>
                                       <div class="layout justify-center">
                                          <v-checkbox
                                             v-model="checkAsesorPlanPagos"
                                             label="Planes de pagos"
                                          ></v-checkbox>
                                       </div>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div class="layout justify-center">
                                          <v-checkbox
                                             v-model="checkAsesorBecas"
                                             label="Becas"
                                          ></v-checkbox>
                                       </div>
                                    </v-flex>
                                 </v-layout>
                              </v-flex>
                              <v-flex xs12 class="text-h6">
                                 <span>Estado de usuario</span>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <div class="layout justify-center">
                                    <v-switch
                                       v-model="swIsAdmin"
                                       label="Es admin"
                                    ></v-switch>
                                 </div>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <div class="layout justify-center">
                                    <v-switch
                                       v-model="swIsEnabled"
                                       label="Activo"
                                    ></v-switch>
                                 </div>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>
                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           text
                           @click.native="closeDialogEditInfo"
                           >Cancelar</v-btn
                        >
                        <v-btn
                           color="primary white--text"
                           @click.native="saveUserInfo"
                           >Guardar</v-btn
                        >
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <!-- DIALOG ELIMINAR USUARIO -->
            <v-dialog v-model="dialogEliminar" persistent max-width="600px">
               <v-form ref="formEliminarAbono">
                  <v-card>
                     <v-card-text
                        class="text--primary"
                        style="margin-top: -20px"
                     >
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5 font-weight-medium"
                                    >Remover usuario</span
                                 >
                              </v-flex>
                              <v-flex xs12>
                                 <span
                                    class="font-weight-regular"
                                    style="font-size: 20px"
                                    >¿Esta seguro que desea remover el
                                    usuario?</span
                                 >
                                 <v-layout wrap style="margin-top: 10px">
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">
                                          Nombre de usuario:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="
                                             border-radius: 10px;
                                             padding: 15px 25px;
                                          "
                                       >
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ username }}</span
                                          >
                                       </v-card>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">
                                          Nombre:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="
                                             border-radius: 10px;
                                             padding: 15px 25px;
                                          "
                                       >
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ asesorNombre }}</span
                                          >
                                       </v-card>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">Tipo:</div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="
                                             border-radius: 10px;
                                             padding: 15px 25px;
                                          "
                                       >
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{
                                                swIsAdmin ? 'Admin' : 'Usuario'
                                             }}</span
                                          >
                                       </v-card>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">
                                          Estado:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="
                                             border-radius: 10px;
                                             padding: 15px 25px;
                                          "
                                       >
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{
                                                swIsEnabled
                                                   ? 'Activo'
                                                   : 'Inactivo'
                                             }}</span
                                          >
                                       </v-card>
                                    </v-flex>
                                 </v-layout>
                              </v-flex>
                              <v-flex xs12 text-center>
                                 <v-icon
                                    color="primary"
                                    size="70"
                                    class="mt-2 mb-2"
                                    >fa-trash-alt</v-icon
                                 >
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="margin-top: -30px; padding-bottom: 20px"
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           dark
                           outlined
                           min-width="150"
                           @click.native="closeDialogEliminar"
                           >No</v-btn
                        >
                        <v-btn
                           color="primary"
                           dark
                           min-width="150"
                           @click.native="deleteUser"
                           >Si, eliminar</v-btn
                        >
                        <v-spacer></v-spacer>
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <!-- DIALOG USUARIO PERMISOS -->
            <v-dialog v-model="dialogPermisos" persistent max-width="600px">
               <v-form>
                  <v-card>
                     <!-- DIALOG DEPENDENCIAS -->
                     <v-dialog
                        v-model="dialogDependencias"
                        persistent
                        max-width="500px"
                     >
                        <v-card>
                           <v-card-text class="text--primary mb-0 pb-0">
                              <v-container grid-list-md>
                                 <v-layout wrap align-center>
                                    <v-flex xs12>
                                       <span class="text-h5 font-weight-medium">
                                          <v-icon left color="primary"
                                             >fa-info-circle</v-icon
                                          >Dependencias encontradas.
                                       </span>
                                    </v-flex>
                                    <v-flex xs12>
                                       <p
                                          v-if="!dependenciasOff"
                                          class="font-weight-medium text-justify"
                                          style="font-size: 14.5px !important"
                                       >
                                          Para la correcta visualización del
                                          modulo de {{ controlador }} se
                                          procederá a habilitar los permisos de
                                          lectura de los modulos dependientes.
                                       </p>
                                       <p
                                          v-else
                                          class="font-weight-medium text-justify"
                                          style="font-size: 14.5px !important"
                                       >
                                          El modulo de {{ controlador }} posee
                                          dependencias con permisos de lectura.
                                          ¿Desea desactivar estos permisos
                                          también?.
                                       </p>
                                    </v-flex>
                                    <v-flex xs12>
                                       <span
                                          class="font-weight-medium dialogsOptionTitlePrimary"
                                          >Dependencias</span
                                       >
                                    </v-flex>
                                    <v-flex xs12 mt-2 mb-2>
                                       <v-card
                                          elevation="0"
                                          class="borderDetallesPrimaryThin"
                                          style="
                                             border-radius: 15px;
                                             margin-top: -15px;
                                             margin-bottom: 10px;
                                          "
                                       >
                                          <v-card-text class="text--primary">
                                             <v-layout fill-height align-center>
                                                <v-flex text-justify>
                                                   <p
                                                      v-for="c in filteredControladores"
                                                      :key="c.controladorId"
                                                      class="font-weight-medium"
                                                      style="
                                                         margin-bottom: 0px;
                                                         font-size: 16px;
                                                      "
                                                   >
                                                      <span>•</span>
                                                      {{ c.nombre }}
                                                   </p>
                                                </v-flex>
                                             </v-layout>
                                          </v-card-text>
                                       </v-card>
                                    </v-flex>
                                 </v-layout>
                              </v-container>
                           </v-card-text>
                           <v-card-actions
                              style="
                                 margin-top: -10px;
                                 padding: 0px 40px 20px 40px;
                              "
                           >
                              <v-layout v-if="!dependenciasOff">
                                 <v-btn
                                    color="primary"
                                    dark
                                    @click.stop="activarDependencias"
                                    >Aceptar</v-btn
                                 >
                                 <v-spacer></v-spacer>
                                 <v-btn
                                    color="amber darken-4"
                                    text
                                    @click.stop="closeDialogDependencias"
                                    >Cancelar</v-btn
                                 >
                              </v-layout>
                              <v-layout v-else>
                                 <v-spacer></v-spacer>
                                 <v-btn
                                    class="mr-1"
                                    color="primary"
                                    dark
                                    outlined
                                    min-width="150"
                                    @click.native="desactivarDependencias"
                                    >Si</v-btn
                                 >
                                 <v-btn
                                    class="ml-1"
                                    color="primary"
                                    dark
                                    min-width="150"
                                    @click.native="closeDialogDependencias"
                                    >No</v-btn
                                 >
                                 <v-spacer></v-spacer>
                              </v-layout>
                           </v-card-actions>
                        </v-card>
                     </v-dialog>

                     <v-card-text class="text--primary pt-1">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12 align-self-center>
                                 <span class="text-h5 font-weight-medium"
                                    >Permisos de {{ asesorNombre }}</span
                                 >
                              </v-flex>
                              <v-flex xs5>
                                 <v-text-field
                                    v-model="searchPermisos"
                                    color="primary"
                                    label="Búsqueda"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-select
                                    v-model="selectTipoPermiso"
                                    :items="cmbTipoPermiso"
                                    color="primary"
                                    item-text="tipo"
                                    item-value="id"
                                    label="Asignar plantilla"
                                    placeholder="Seleccionar..."
                                 ></v-select>
                              </v-flex>
                              <v-flex xs12 mb-6>
                                 <v-data-table
                                    :headers="headersPermisos"
                                    :items="permisos"
                                    :search="searchPermisos"
                                    :items-per-page="-1"
                                    :footer-props="{
                                       itemsPerPageOptions: [-1]
                                    }"
                                    height="300"
                                    no-results-text="Búsqueda sin resultados"
                                    class="elevation-0"
                                    hide-default-footer
                                    fixed-header
                                    multi-sort
                                 >
                                    <template #item.controlador="{ item }">
                                       {{ item.controlador }}
                                       <v-tooltip
                                          v-if="item.dependencias.trim() != ''"
                                          bottom
                                          max-width="300"
                                          color="primary"
                                       >
                                          <template #activator="{ on }">
                                             <v-icon
                                                small
                                                right
                                                color="primary"
                                                v-on="on"
                                                >fa-project-diagram</v-icon
                                             >
                                          </template>
                                          <span style="font-size: 15px"
                                             >Depende de otros modulos</span
                                          >
                                       </v-tooltip>
                                    </template>

                                    <template #item.ver="{ item }">
                                       <div class="layout justify-center">
                                          <v-checkbox
                                             v-model="item.ver"
                                             color="primary"
                                             style="margin-left: 5px"
                                             @change="checkVer(item)"
                                          ></v-checkbox>
                                       </div>
                                    </template>

                                    <!-- MOSTRAR CHECKBOXS -->
                                    <template #item.crear="{ item }">
                                       <div class="layout justify-center">
                                          <v-checkbox
                                             v-if="
                                                item.controladorId != 6 &&
                                                item.controladorId != 7 &&
                                                item.controladorId != 8 &&
                                                item.controladorId != 12 &&
                                                item.controladorId != 13 &&
                                                item.controladorId != 16 &&
                                                item.controladorId != 39
                                             "
                                             v-model="item.crear"
                                             color="primary"
                                             style="margin-left: 5px"
                                             :disabled="!item.ver"
                                             @change="setPredeterminado"
                                          ></v-checkbox>
                                          <span
                                             v-else
                                             style="
                                                color: var(--v-primary-base);
                                                font-weight: bold;
                                                font-size: 16px;
                                             "
                                             >NA</span
                                          >
                                       </div>
                                    </template>

                                    <template #item.editar="{ item }">
                                       <div class="layout justify-center">
                                          <v-checkbox
                                             v-if="
                                                item.controladorId != 7 &&
                                                item.controladorId != 8 &&
                                                item.controladorId != 13 &&
                                                item.controladorId != 39
                                             "
                                             v-model="item.editar"
                                             color="primary"
                                             style="margin-left: 5px"
                                             :disabled="!item.ver"
                                             @change="setPredeterminado"
                                          ></v-checkbox>
                                          <span
                                             v-else
                                             style="
                                                color: var(--v-primary-base);
                                                font-weight: bold;
                                                font-size: 16px;
                                             "
                                             >NA</span
                                          >
                                       </div>
                                    </template>

                                    <template #item.eliminar="{ item }">
                                       <div class="layout justify-center">
                                          <v-checkbox
                                             v-if="
                                                item.controladorId != 6 &&
                                                item.controladorId != 7 &&
                                                item.controladorId != 8 &&
                                                item.controladorId != 12 &&
                                                item.controladorId != 13 &&
                                                item.controladorId != 16 &&
                                                item.controladorId != 39 &&
                                                item.controladorId != 40
                                             "
                                             v-model="item.eliminar"
                                             color="primary"
                                             style="margin-left: 5px"
                                             :disabled="!item.ver"
                                             @change="setPredeterminado"
                                          ></v-checkbox>
                                          <span
                                             v-else
                                             style="
                                                color: var(--v-primary-base);
                                                font-weight: bold;
                                                font-size: 16px;
                                             "
                                             >NA</span
                                          >
                                       </div>
                                    </template>

                                    <!-- Si no hay datos, mostrar boton para listar de nuevo -->
                                    <template slot="no-data">
                                       <div v-if="permisos.length == 0">
                                          <v-container>
                                             <v-layout wrap align-center>
                                                <v-flex
                                                   xs12
                                                   class="text-center"
                                                >
                                                   <v-btn color="primary"
                                                      >Sin permisos</v-btn
                                                   >
                                                </v-flex>
                                             </v-layout>
                                          </v-container>
                                       </div>
                                    </template>
                                 </v-data-table>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>
                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           text
                           @click.native="closeDialogPermisos"
                           >Salir</v-btn
                        >
                        <v-btn
                           color="primary white--text"
                           @click.native="saveUserPermisos"
                           >Guardar</v-btn
                        >
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <!-- DIALOG PASSWORD -->
            <v-dialog v-model="dialogPassword" persistent max-width="500px">
               <v-form>
                  <v-card>
                     <v-card-text class="text--primary">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5 font-weight-medium"
                                    >Cambiar contraseña de
                                    {{ asesorNombre }}</span
                                 >
                              </v-flex>
                           </v-layout>
                           <v-layout wrap>
                              <v-flex v-if="false" xs12>
                                 <v-text-field
                                    v-model="id"
                                    disabled
                                    label="ID"
                                    prepend-icon="far fa-id-card"
                                    color="primary"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex>
                                 <v-text-field
                                    v-model="newPassword"
                                    label="Nueva contraseña"
                                    :append-icon="
                                       !showPassword ? 'fa-eye' : 'fa-eye-slash'
                                    "
                                    :type="showPassword ? 'text' : 'password'"
                                    prepend-icon="fas fa-unlock-alt"
                                    @click:append="showPassword = !showPassword"
                                    @keypress.enter="cambiarPassword()"
                                 ></v-text-field>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>
                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           text
                           @click.native="closeDialogPassword"
                           >Salir</v-btn
                        >
                        <v-btn
                           color="primary white--text"
                           @click.native="cambiarPassword"
                           >Cambiar</v-btn
                        >
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <v-layout wrap>
               <v-flex xs12>
                  <v-data-table
                     :headers="headers"
                     :items="usuarios"
                     :search="search"
                     :items-per-page="10"
                     :footer-props="{
                        itemsPerPageOptions: [10, 25, 50],
                        itemsPerPageText: 'Filas por página:'
                     }"
                     no-results-text="Búsqueda sin resultados"
                     class="elevation-0"
                     multi-sort
                  >
                     <template #top>
                        <!-- TOOLBAR -->
                        <v-toolbar color="white" flat>
                           <v-toolbar-title style="font-weight: 500"
                              >Administración de usuarios</v-toolbar-title
                           >
                           <v-spacer></v-spacer>
                           <v-text-field
                              ref="txtBusqueda"
                              v-model="searchComputed"
                              color="primary"
                              append-icon="fa-search"
                              label="Búsqueda"
                              single-line
                              class="mt-3"
                           ></v-text-field>
                           <v-spacer></v-spacer>
                           <v-btn color="primary" @click="showCrearUsuario"
                              >Nuevo usuario</v-btn
                           >
                        </v-toolbar>
                     </template>

                     <template #item.nivelAsesor="{ item }">
                        <div>
                           {{
                              item.nivelAsesor == 0
                                 ? 'Sin nivel'
                                 : 'Nivel ' + item.nivelAsesor
                           }}
                        </div>
                        <span class="colorPrimary font-weight-medium">{{
                           item.asesorPlanPagos ? 'Planes de pago' : ''
                        }}</span>
                        <span
                           v-if="item.asesorPlanPagos && item.asesorBecas"
                           class="colorPrimary font-weight-medium"
                           >,
                        </span>
                        <span class="colorPrimary font-weight-medium">{{
                           item.asesorBecas ? 'Becas' : ''
                        }}</span>
                     </template>

                     <template #item.isAdmin="{ item }">
                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-icon color="primary" v-on="on">{{
                                 item.isAdmin ? 'fa-check' : 'fa-times'
                              }}</v-icon>
                           </template>
                           <span style="font-size: 15px">{{
                              item.isAdmin ? 'Admin' : 'Usuario'
                           }}</span>
                        </v-tooltip>
                     </template>

                     <template #item.isEnabled="{ item }">
                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-icon color="primary" v-on="on">
                                 {{
                                    item.isEnabled
                                       ? 'fa-toggle-on'
                                       : 'fa-toggle-off'
                                 }}
                              </v-icon>
                           </template>
                           <span style="font-size: 15px">{{
                              item.isEnabled ? 'Activo' : 'Inactivo'
                           }}</span>
                        </v-tooltip>
                     </template>

                     <template #item.permisos="{ item }">
                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-btn
                                 dark
                                 color="amber darken-4"
                                 v-on="on"
                                 @click="showDialogPermisos(item)"
                                 >Permisos</v-btn
                              >
                           </template>
                           <span style="font-size: 15px">
                              Permisos de Usuarios
                           </span>
                        </v-tooltip>
                     </template>

                     <template #item.opciones="{ item }">
                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-btn
                                 text
                                 fab
                                 small
                                 color="amber darken-3"
                                 v-on="on"
                                 @click="showDialogEditar(item)"
                              >
                                 <v-icon>fa-pen</v-icon>
                              </v-btn>
                           </template>
                           <span style="font-size: 15px"> Editar </span>
                        </v-tooltip>

                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-btn
                                 text
                                 fab
                                 small
                                 color="orange darken-3"
                                 v-on="on"
                                 @click="showDialogPassword(item)"
                              >
                                 <v-icon>fas fa-unlock-alt</v-icon>
                              </v-btn>
                           </template>
                           <span style="font-size: 15px">
                              Cambiar Contraseña
                           </span>
                        </v-tooltip>

                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-btn
                                 text
                                 small
                                 fab
                                 color="primary"
                                 v-on="on"
                                 @click="showDialogEliminar(item)"
                              >
                                 <v-icon>fa-trash-alt</v-icon>
                              </v-btn>
                           </template>
                           <span style="font-size: 15px"> Eliminar </span>
                        </v-tooltip>
                     </template>

                     <!-- Si no hay datos, mostrar boton para listar de nuevo -->
                     <template slot="no-data">
                        <div v-if="usuarios.length == 0">
                           <v-container>
                              <v-layout wrap align-center>
                                 <v-flex xs12 class="text-center">
                                    <v-btn color="primary">Sin usuarios</v-btn>
                                 </v-flex>
                              </v-layout>
                           </v-container>
                        </div>
                     </template>
                  </v-data-table>
               </v-flex>
            </v-layout>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import { authComputed } from '../helpers'

export default {
   name: 'AdmUsuarios',
   data: () => ({
      search: '',
      searchPermisos: '',
      dialogUserInfo: false,
      dialogEliminar: false,
      dialogPermisos: false,
      dialogPassword: false,
      dialogDependencias: false,
      dependenciasOff: false,
      selectNivelAsesor: 0,
      selectTipoPermiso: 2,
      checkAsesorPlanPagos: false,
      checkAsesorBecas: false,
      cmbNiveles: [
         {
            id: 0,
            nombre: 'Sin nivel'
         },
         {
            id: 1,
            nombre: 'Nivel 1'
         },
         {
            id: 2,
            nombre: 'Nivel 2'
         }
      ],
      cmbTipoPermiso: [
         {
            id: 1,
            tipo: 'Vacio'
         },
         {
            id: 2,
            tipo: 'Predeterminado'
         },
         {
            id: 4,
            tipo: 'Completo'
         }
      ],
      // Ver -1, Editar 0, Crear 1
      editedIndex: -1,
      usuarios: [],
      permisos: [],
      controlador: '',
      controladores: [],
      dependencias: [],
      headers: [
         {
            text: 'UserName',
            value: 'userName',
            class: 'titulo'
         },
         {
            text: 'Nombre',
            value: 'asesorNombre',
            sortable: false,
            class: 'titulo'
         },
         {
            text: 'Es admin',
            value: 'isAdmin',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Activo',
            value: 'isEnabled',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Nivel de asesor',
            value: 'nivelAsesor',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Conf. permisos',
            value: 'permisos',
            sortable: false,
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            class: 'titulo',
            align: 'right'
         }
      ],
      headersPermisos: [
         {
            text: 'Modulo',
            value: 'modulo',
            class: 'titulo'
         },
         {
            text: 'Sub modulo',
            value: 'controlador',
            class: 'titulo'
         },
         {
            text: 'Ver',
            value: 'ver',
            sortable: false,
            class: 'titulo',
            align: 'center'
         } /*,
         {
            text: 'Crear',
            value: 'crear',
            sortable: false,
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Editar',
            value: 'editar',
            sortable: false,
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Eliminar',
            value: 'eliminar',
            sortable: false,
            class: 'titulo',
            align: 'center'
         }*/
      ],

      // Informacion de usuario
      id: '',
      username: '',
      password: '',
      newPassword: '',
      showPassword: false,
      swIsAdmin: false,
      swIsEnabled: false,
      asesorNombre: '',

      // User claims
      name: ''
   }),
   computed: {
      ...authComputed,
      // Agregar debounce a input search
      searchComputed: {
         get() {
            return this.search
         },
         set: debounce(function (newVal) {
            this.search = newVal.trim()
         }, 500)
      },
      filteredControladores() {
         return this.controladores.filter((i) => {
            return this.dependencias.includes(i.controladorId.toString())
         })
      }
   },
   watch: {
      selectNivelAsesor(val) {
         if (val == 0) {
            this.checkAsesorPlanPagos = false
            this.checkAsesorBecas = false
         }
      },
      selectTipoPermiso(val) {
         if (val == 1) {
            // Vacio
            this.permisos.forEach((el) => {
               el.ver = false
               el.crear = false
               el.editar = false
               el.eliminar = false
            })
         } else if (val == 2) {
            // Predeterminado
            // Do nothing
         } else if (val == 3) {
            // Solo lectura
            this.permisos.forEach((el) => {
               el.ver = true
               el.crear = false
               el.editar = false
               el.eliminar = false
            })
         } else if (val == 4) {
            // Completo
            this.permisos.forEach((el) => {
               el.ver = true
               el.crear = true
               el.editar = true
               el.eliminar = true
            })
         }
      }
   },
   created() {
      if (this.oidcUser.role == 'admin') {
         this.listarUsuarios()
      } else {
         this.$router.push({ name: 'home' })
      }
   },
   methods: {
      // Cambiar contraseña de usuario
      cambiarPassword() {
         axios({
            method: 'put',
            url: 'api/UserManagement/password/' + this.id,
            baseURL: process.env.VUE_APP_IDENTITY,
            data: {
               password: this.newPassword
            }
         })
            .then(() => {
               this.$store.dispatch('showSnack', {
                  message: 'Contraseña cambiada exitosamente.',
                  color: 'snackSuccess'
               })
               this.closeDialogPassword()
            })
            .catch((error) => {
               console.log(error)
               this.$store.dispatch('showSnack', {
                  message: 'No se pudo cambiar la contraseña.',
                  color: 'snackError'
               })
            })
      },
      // Activar dependencias
      activarDependencias() {
         this.permisos.forEach((el) => {
            if (this.dependencias.includes(el.controladorId.toString())) {
               el.ver = true
            }
         })
         this.closeDialogDependencias()
      },
      // Desactivar dependencias
      desactivarDependencias() {
         this.permisos.forEach((el) => {
            if (this.dependencias.includes(el.controladorId.toString())) {
               el.ver = false
               el.crear = false
               el.editar = false
               el.eliminar = false
            }
         })
         this.closeDialogDependencias()
      },
      // Validando checkbox de Ver
      setPredeterminado() {
         this.selectTipoPermiso = 2
      },
      // Validando checkbox de Ver
      checkVer(item) {
         this.setPredeterminado()
         if (!item.ver) {
            // Si se pasa a false se vuelven false los demas
            item.crear = false
            item.editar = false
            item.eliminar = false

            if (item.dependencias.trim() != '') {
               this.controlador = item.controlador
               this.dependencias = item.dependencias.split(',')
               this.dialogDependencias = true
               this.dependenciasOff = true
            }
         } else {
            // Si se pasa a true se revisa si tiene dependencias
            if (item.dependencias.trim() != '') {
               this.controlador = item.controlador
               this.dependencias = item.dependencias.split(',')
               this.dialogDependencias = true
            }
         }
      },
      // Dialog en modo crear usuario
      showCrearUsuario() {
         this.dialogUserInfo = true
         this.editedIndex = 1
      },
      // Listar usuarios
      listarUsuarios() {
         axios({
            method: 'get',
            url: 'api/UserManagement/clienteweb',
            baseURL: process.env.VUE_APP_IDENTITY
         }).then((response) => {
            var result = response.data
            var index = 0
            result.forEach((el) => {
               result[index].asesorNombre = el.userClaims.find(
                  (x) => x.claimType == 'name'
               ).claimValue
               index++
            })
            this.usuarios = result
         })
      },
      // Listar modulos
      listarModulos() {
         axios('api/controlador').then((response) => {
            this.controladores = response.data
         })
      },
      // Editar informacion
      saveUserInfo() {
         if (this.editedIndex == 0) {
            axios({
               method: 'put',
               url: 'api/UserManagement/' + this.id,
               baseURL: process.env.VUE_APP_IDENTITY,
               data: {
                  id: this.id,
                  userName: this.username,
                  isAdmin: this.swIsAdmin,
                  isEnabled: this.swIsEnabled,
                  clienteVue: true,
                  nivelAsesor: this.selectNivelAsesor,
                  asesorPlanPagos: this.checkAsesorPlanPagos,
                  asesorBecas: this.checkAsesorBecas,
                  claimName: this.asesorNombre
               }
            })
               .then(() => {
                  this.$store.dispatch('showSnack', {
                     message: 'Información editada exitosamente.',
                     color: 'snackSuccess'
                  })
                  this.closeDialogEditInfo()
                  this.listarUsuarios()
                  this.clear()
               })
               .catch((error) => {
                  console.log(error)
                  this.$store.dispatch('showSnack', {
                     message: 'No se pudo editar.',
                     color: 'snackError'
                  })
               })
         } else if (this.editedIndex == 1) {
            axios({
               method: 'post',
               url: 'api/UserManagement/',
               baseURL: process.env.VUE_APP_IDENTITY,
               data: {
                  id: this.id,
                  userName: this.username,
                  isAdmin: this.swIsAdmin,
                  isEnabled: this.swIsEnabled,
                  clienteWeb: true,
                  claimName: this.name,
                  password: this.password,
                  nivelAsesor: this.selectNivelAsesor,
                  asesorPlanPagos: this.checkAsesorPlanPagos,
                  asesorBecas: this.checkAsesorBecas
               }
            })
               .then((response) => {
                  var user = response.data
                  // Creando permisos en false
                  axios
                     .post('api/permisos/' + user.id)
                     .then(() => {
                        this.$store.dispatch('showSnack', {
                           message: 'Usuario creado exitosamente.',
                           color: 'snackSuccess'
                        })
                        this.closeDialogEditInfo()
                        this.listarUsuarios()
                        this.clear()
                     })
                     .catch((error) => {
                        console.log(error)
                        this.$store.dispatch('showSnack', {
                           message:
                              'No se pudieron crear los permisos del usuario.',
                           color: 'snackError'
                        })
                     })
               })
               .catch((error) => {
                  console.log(error)
                  this.$store.dispatch('showSnack', {
                     message: 'No se pudo crear el usuario.',
                     color: 'snackError'
                  })
               })
         }
      },
      // Editar informacion
      saveUserPermisos() {
         axios
            .put('api/permisos/' + this.id, {
               permisos: this.permisos
            })
            .then(() => {
               this.$store.dispatch('showSnack', {
                  message: 'Permisos editados exitosamente.',
                  color: 'snackSuccess'
               })
               this.closeDialogPermisos()
               this.listarUsuarios()
               this.clear()
            })
            .catch((error) => {
               console.log(error)
               this.$store.dispatch('showSnack', {
                  message: 'No se pudo editar los permisos.',
                  color: 'snackError'
               })
            })
      },
      // Borrar usuario
      deleteUser() {
         axios
            .delete('api/permisos/' + this.id)
            .then(() => {
               axios({
                  method: 'delete',
                  url: 'api/UserManagement/' + this.id,
                  baseURL: process.env.VUE_APP_IDENTITY,
                  data: {
                     id: this.id
                  }
               })
                  .then(() => {
                     this.$store.dispatch('showSnack', {
                        message: 'Usuario eliminado exitosamente.',
                        color: 'csnackSuccessolor'
                     })
                     this.closeDialogEliminar()
                     this.listarUsuarios()
                     this.clear()
                  })
                  .catch((error) => {
                     console.log(error)
                     this.$store.dispatch('showSnack', {
                        message: 'No se pudo eliminar el usuario.',
                        color: 'snackError'
                     })
                  })
            })
            .catch((error) => {
               console.log(error)
               this.$store.dispatch('showSnack', {
                  message: 'No se pudieron eliminar los permisos.',
                  color: 'snackError'
               })
            })
      },
      // Mostrar dialog editar
      showDialogEditar(item) {
         this.dialogItem = 0
         this.editedIndex = 0
         this.id = item.id
         this.username = item.userName
         this.asesorNombre = item.asesorNombre
         this.selectNivelAsesor = item.nivelAsesor
         this.checkAsesorPlanPagos = item.asesorPlanPagos
         this.checkAsesorBecas = item.asesorBecas
         this.swIsAdmin = item.isAdmin
         this.swIsEnabled = item.isEnabled
         this.dialogUserInfo = true

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Mostrar dialog eliminar
      showDialogEliminar(item) {
         this.id = item.id
         this.username = item.userName
         this.asesorNombre = item.asesorNombre
         this.swIsAdmin = item.isAdmin
         this.swIsEnabled = item.isEnabled
         this.dialogEliminar = true

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Mostrar dialog permisos
      showDialogPermisos(item) {
         axios
            .get('api/permisos/' + item.id)
            .then((response) => {
               this.permisos = response.data
               this.id = item.id
               this.username = item.userName
               this.asesorNombre = item.asesorNombre
            })
            .catch((error) => {
               console.log(error)
            })
         this.dialogPermisos = true
      },
      showDialogPassword(item) {
         this.id = item.id
         this.username = item.userName
         this.asesorNombre = item.asesorNombre
         this.dialogPassword = true
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar dialog editar
      closeDialogEditInfo() {
         this.editedIndex = -1
         this.dialogUserInfo = false
         this.clear()
      },
      // Cerrar dialog eliminar
      closeDialogEliminar() {
         this.dialogEliminar = false
         this.clear()
      },
      // Cerrar dialog eliminar
      closeDialogPermisos() {
         this.searchPermisos = ''
         this.dialogPermisos = false
         this.clear()
      },
      // Cerrar dialog dependencias
      closeDialogDependencias() {
         this.dialogDependencias = false
         this.controlador = ''
         this.dependencias = []
         this.dependenciasOff = false
      },
      // Cerrar dialog de cambio de password
      closeDialogPassword() {
         this.id = ''
         this.username = ''
         this.asesorNombre = ''
         this.newPassword = ''
         this.dialogPassword = false
      },
      // Limpiar usuario
      clear() {
         this.id = ''
         this.username = ''
         this.asesorNombre = ''
         this.password = ''
         this.showPassword = false
         this.name = ''
         this.swIsAdmin = false
         this.swIsEnabled = false
         this.checkAsesorPlanPagos = false
         this.checkAsesorBecas = false
         this.permisos = []
         this.selectTipoPermiso = 2
         this.controlador = ''
         this.dependencias = []
      }
   }
}
</script>
